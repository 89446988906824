.container {
  display: flex;
  flex-direction: column;
}

.component {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  align-items: center;
  min-height: 50px;
  height: 100vh;
}

.partnercomponent {
  margin-top: auto;
}

.slider {
  height: 35vh !important;
}
