.carousel-section {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #000;
  color: #fff;
  margin-top: 15%;
}

.carousel-toggle {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.toggle-button {
  padding: 15px 30px;
  font-size: 1.2rem;
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.toggle-button.active {
  background: #fff;
  color: #000;
}

.toggle-button:hover {
  transform: translateY(-2px);
}

.carousel-container {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
  margin-bottom: 30px;
}

.carousel-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
  background-color: #000;
  padding: 20px;
}

.carousel-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 100px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  border-radius: 0 0 8px 8px;
  text-align: center;
}

.carousel-info h3 {
  font-size: 2rem;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.carousel-info p {
  font-size: 1.4rem;
  opacity: 0.9;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 28px;
  cursor: pointer;
  z-index: 2;
  transition: background-color 0.3s ease;
}

.carousel-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.carousel-button.prev {
  left: 30px;
}

.carousel-button.next {
  right: 30px;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 30px;
}

.dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background: #fff;
}

@media (max-width: 1200px) {
  .carousel-container {
    height: 600px;
  }
}

@media (max-width: 768px) {
  .carousel-section {
    margin: 20px auto;
    padding: 20px 10px;
    margin-top: 0;
  }

  .carousel-container {
    height: 450px;
  }

  .carousel-info {
    padding: 20px;
  }

  .carousel-info h3 {
    font-size: 1.6rem;
  }

  .carousel-info p {
    font-size: 1.2rem;
  }

  .toggle-button {
    padding: 12px 24px;
    font-size: 1.1rem;
  }

  .carousel-button {
    width: 50px;
    height: 50px;
    font-size: 24px;
  }

  .carousel-slide img {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .carousel-container {
    height: 350px;
  }

  .carousel-button {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }

  .carousel-info h3 {
    font-size: 1.3rem;
  }

  .carousel-info p {
    font-size: 1rem;
  }

  .carousel-slide img {
    padding: 5px;
  }
}
